.text__start {
	font-family: 'latoregular';
	font-size: 18px;
	color: $darkblue;
	line-height: 1.5;
}

.stock__item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	position: relative;
	margin-bottom: 20px;
	&>p {
		display: block;
	}
}

.stock__images {
	margin-right: 25px;
	width: 500px;
	height: 380px;	
	&>.slick-prev {
	background-color: rgba(#1c4aa9, 0.6);
	width: 46px;
	height: 46px;
	z-index: 999;
	left: 0;
	// position: relative;
	&:before {
		content:'\f2fa';	
		font-family: 'Material-Design-Iconic-Font';
		font-size: 26px;	
	}
	}
	&>.slick-next {
		background-color: rgba(#1c4aa9, 0.6);
		width: 46px;
		height: 46px;
		z-index: 999;
		left: calc(100% - 46px);
		&:before {
			content:'\f2fb';	
			font-family: 'Material-Design-Iconic-Font';
			font-size: 26px;
		}
		}	
		&>.slick-dots {
			top: calc(100% - 12px);
			&>li {
				//display: block;
				width: 79px;
				height: 4px;
				background-color: rgba(white,0.6);
			}
			&>.slick-active {
				background-color: #fff;
			}
		}
@include px1024 {
	width: 100%;
}
}

.stock__label_wrapper {
	position: absolute;
	left: 20px;
	top: 20px;
	z-index: 9999;
	margin: 0;
	padding: 10px 20px;
	transform: skew(-15deg);
}

.stock__label {
	font-family: 'latobold';
	font-size: 18px;
	margin: 0;
	padding: 0;
	transform: skew(15deg);
	text-transform: uppercase;
	color: white;
}

.stock__image {
	width: 500px;
	height: 380px;

	@include px1024 {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

}

.stock-titleline {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.stock__title {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: $darkblue;
	text-transform: uppercase;

	@include px600 {
		font-size: 16px;
	}
}

.stock__price {
	font-family: 'latobold';
	font-size: 20px;
	color: $red;

	@include px600 {
		font-size: 16px;
	}
}

.stock__subtitle {
	font-family: 'latobold';
	font-size: 18px;
	color: #9091a2;
	margin-bottom: 20px;
	display: block;
}

.stock__descr {
	width: calc(100% - 525px);


	@include px1024 {
		width: 100%;
	}

	@include px600 {
		font-size: 12px;
	}

	@include px420 {
		font-size: 10px;
	}
}

.stock__property {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.stock__property--name,.stock__property--value {
	font-family: 'latobold';
	font-size: 14px;
	color: #2b2d42;
	background-color: white;
	z-index: 999;
	padding: 0px 5px 0 5px;
	margin-left: -5px;
}

.stock__line {
	border-bottom: 1px solid #ebebee;
	display: inline-block;
	position: absolute;
	width: 100%;
	top: calc(100% - 5px);
	z-index: 998;
}

.stock__offer {
	font-family: 'latobold';
	font-size: 18px;
	background-color: $darkblue;
	transition: .2s;
	color: white;
	padding: 19px 44px;
	text-transform: uppercase;
	margin-top: -3px;
	display: block;
	position: relative;
	cursor: pointer;
	&:hover {
		background-color: $red;
		transition: .2s;
	}
	&:after {
		content: '\f301';
		font-family: 'Material-Design-Iconic-Font';
		font-size: 24px;
		color: white;
		padding: 15px 20px 16px 20px;
		background-color: $red;
		position: absolute;
		top: 0;
		right: 0px;
		border-left: 2px solid white;
	}

	@include px767 {
		font-size: 10px;
		padding: 18px 20px 18px 5px;
	}
}

.stock__dop {
	background-color: #edeff3;
	padding: 38px 30px;
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;
	line-height: 1.5;
	width: 100%;
	&>p {
		display: block;
	}

	@include px768 {
		font-size: 12px;
		padding: 10px;
	}
}
