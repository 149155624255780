.part-page {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	margin-top: 27px;
	flex-wrap: wrap;

}

.part-page__image {
	width: 398px;
	min-width: 398px;
	height: 299px;
	min-height: 299px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 25px;
	position: relative;
	cursor: pointer;
	&:after {
		content: url('../images/part_zoom.png');
		width: 60px;
		height: 60px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		display: none;
	}
	&:hover:after {
		display: block;
	}
}

.part-page__descr {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% - 398px - 25px);
	position: relative;

	@include px767 {
		width: 100%;
	}
}

.part-page__title {
	font-family: 'russo_oneregular';
	font-size: 20px;
	color: $darkblue;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.part-page__stock {
	font-family: 'russo_oneregular';
	font-size: 12px;
	color: white;
	padding: 5px;
	text-transform: uppercase;
	margin-bottom: 20px;
	&_aviable {
		background-color: #3aa249;
	}
}

.part-page__text {
	font-family: 'latobold';
	font-weight: bold;
	font-size: 14px;
	color: #2b2d42;
	line-height: 26px;
}

.part-page__price {
	font-family: 'latobold';
	font-weight: bold;
	font-size: 18px;
	color: $red;
	text-transform: uppercase;
	margin-top: 20px;
}

.part-page-count {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	width: 100%;
	
}

.part-page_add {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.part-page__addcount {
	font-family: 'latobold';
	font-weight: bold;
	font-size: 16px;
	color: white;
	text-transform: uppercase;
	background-color: $darkblue;
	padding: 13px 18px;
	cursor: pointer;
}

.part-page__count {
	font-family: 'latobold';
	font-weight: bold;
	font-size: 20px;
	color: #9091a2;
	text-transform: uppercase;
	background-color: #f4f5f7;
	height: 45px;
	min-height: 30px;
	cursor: pointer;
	width: 45px;
	text-align: center;
}

.part-page__longdescr {
	margin-top: 20px;
}

.part-page_descr-title {
	font-family: 'latobold';
	font-size: 18px;
	color: $darkblue;
}

.part-page_descr-text {
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;
	line-height: 24px;

	@include px420 {
		font-size: 14px;
	}
}

.part-page_dop {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: $darkblue;
	text-transform: uppercase;
	margin-top: 50px;
	margin-bottom: 30px;
	display: block;

	@include px420 {
		font-size: 18px;
	}
}

.dop__items {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: stretch;
	width: 100%;
	flex-wrap: wrap;

	@include px420 {
		padding: 5px;
		min-width: 100%;
		max-width: 100%;
	}
}

.dop__item {
	border: 1px solid #dddee3;
	width: 350px;
	max-width: 350px;
	min-width: 350px;
	margin-bottom: 30px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;


	@include px420 {
		width: 100%;
		min-width: 100%;
	}

}

.dop__image {
	height: 300px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	border-bottom: 1px solid #dddee3;;

	@include px420 {
		width: 100%;
	}
}

.dop__title {
	font-family: 'latobold';
	font-size: 20px;
	color: $darkblue;
	text-transform: uppercase;
	margin: 25px;
	&>a {
		font-family: 'latobold';
		font-size: 20px;
		color: $darkblue;
		text-transform: uppercase;	
		text-decoration: none;	
	}

	@include px420 {
		font-size: 14px;
	}
}

.dop__price {
	font-family: 'latobold';
	font-size: 20px;
	color: $red;
	text-transform: uppercase;
	margin: 25px;
}