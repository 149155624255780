.separator__title {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: $darkblue;
	text-transform: uppercase;
}

.separator__items {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;

	@include px768 {
		justify-content: center;
	}
}

.separator__item {
	width: 370px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border: 1px solid #dddee3;
	position: relative;
	&>.part-page-count>.add-basket>.search_add {
		width: 190px;
		@include px420 {
			width: 100px;
		}
	}
	margin-right: 20px;
	margin-bottom: 40px;

	@include px420 {
		width: 100%;

	} 
	position: relative;
}


.part-page-count {
	position: absolute;
	left: 0;
	// top: 100%;
	// transform: translate(0,-100%);
	top: 100%;
	transform: translate(0, -100%);	
}

.pp_product {
	@include px600 {
		position: relative;
		top: auto;
		
	}
}

.pp_product>.part-page-count {
	@include px600 {
		position: relative;
		top: auto;
		margin-top: 50px;
	}
}

.separator__image {
	width: 100%;
	height: 260px;
	background-size: contain;
	background-repeat: no-repeat;
	border-bottom: 1px solid #dddee3;
}

.separator__item_title,.separator__price,.separator__text {
	margin: 20px 0 30px 20px;
}

.separator__item_title {
	font-family: 'latobold';
	font-size: 22px;
	color: $darkblue;
	text-transform: uppercase;
	margin: 10px 0 10px 20px;
}

.separator__price {
	font-family: 'latobold';
	font-size: 18px;
	color: $red;
	text-transform: uppercase;
	margin: 10px 0 10px 20px;
}

.separator__text {
	font-family: 'latoregular';
	font-size: 14px;
	color: $darkblue;	
	margin: 10px 0 30px 20px;
}


.separator__prop {
	width: 100%;
	//min-width: 767px;
	overflow-x: auto;

	& table {
		width: 100%;
		min-width: 767px;
		table-layout: fixed;
		margin-bottom: 30px;
		&>tr>td {
			
		}
		&>tbody tr:nth-child(odd) {
			background-color: #edeff3;
	
		}
		& td {
			width: 100%;
			padding: 10px;
			font-family: 'latoregular';
			font-size: 16px;
			color: #2b2d42;
		}		
	}
}

.separator_model--table-wrapper {
	width: 100%;
	overflow-x: auto;
	&>table {
		width: 100% !important;
		border: 0 !important;
    min-width: 767px;
    table-layout: fixed;
		margin-bottom: 30px;
		&>tbody {

		}
		&>tbody>tr:nth-child(odd) {
			background-color: #edeff3;
		}
		&>tbody>tr>td {
		width: 100%;
    padding: 10px;
    font-family: 'latoregular';
    font-size: 16px;
    color: #2b2d42;
		}
	}
}
.separator__table {
	width: 100%;
	min-width: 767px;
	table-layout: fixed;
	margin-bottom: 30px;
	&>tr>td {
		
	}
	&>tbody tr:nth-child(odd) {
		background-color: #edeff3;

	}
	& td {
		width: 100%;
		padding: 10px;
		font-family: 'latoregular';
		font-size: 16px;
		color: #2b2d42;
	}
}