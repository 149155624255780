.header {
	width: 1180px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@include px1180 {
		width: 100%;
		padding: 0 20px;
	}
	@include px420 {
		padding: 0;
		border-bottom: 1px solid white;
	}
	
}

.logo {
	position: relative;
	width: 200px;
	min-width: 292px;
	height: 110px;
	margin-left: 25px;
	left: 6px;
	margin-right: -30px;
	@include px1024 {
		width: 230px;	
		min-width: 230px;	
		left: 0;
	}
}

.logo__bg {
	transform: skew(-20deg,0);
	background-color: $blue;
	height: 110px;
	width: calc(100% - 40px);
	position: absolute;
}

.logo__text {
	position: absolute;
	left: calc(50% - 10px);
	top: 50%;
	transform: translate(-50%,-50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

}

.logo__title {
	font-family: "russo_oneregular";
	color: white;
	font-size: 38px;
	text-transform: uppercase;
	letter-spacing: 15px;

	@include px1024 {
		font-size: 26px;	
	}

	@include px768 {
		letter-spacing: 10px;
	}
}

.logo__subtitle {
	font-family: "latoregular";
	color: white;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 12px;

	@include px1024 {
		font-size: 12px;
	}
	@include px768 {
		letter-spacing: 9px;
	}	
}

.header__info {
	margin-left: 0px;
	width: 350px;
	max-width: 350px;

	@include px1280 {
		width: auto;
	}
}

.header__slogan {
	font-family: "latoregular";
	font-size: 16px;
	color: #9091a2;
	margin-bottom: 10px;
	@include px1024 {
		font-size: 11px;
	}

	@include px600 {
		display: none;
	}
}

.header__adress-block {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	@include px768 {
		display: none;
	}
}

.header__adress {
	margin: 0 10px 15px 0;
	font-family: "latoregular";
	font-size: 14px;
	letter-spacing: 0.4px;
	&:before {
		content: '';
		font-family: 'Material-Design-Iconic-Font';
		color: $red;
		margin-right: 5px;
	}

	@include px768 {
		font-size: 12px;
	}
}

.header__adress-url {
    font-family: 'latoregular';
	font-size: 12px;	
	text-decoration: none;
	color: $blue;
}

.header__job {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: -10px;

	@include px1024 {
		display: none;
	}
}

.header__job-title {
	font-family: "latoregular";
	font-size: 16px;
	color: $blue;
	margin-bottom: 1px;
	margin-top: 10px;
	
}

.header__job-subtitle {
	font-family: "latoregular";
	font-size: 16px;
	color: $black;
}

.header__phones {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@include px600 {
		display: none;
	}
}

.header__phones-email {
	font-family: "latoregular";
	font-size: 14px;
	color: $dark;
	border-bottom: 1px dotted $dark;
	text-decoration: none;
	margin-bottom: 20px;

	@include px768 {
		display: none;
	}
}

.header__phones-phone {
	font-family: "russo_oneregular";
	color: $dark;
	font-size: 22px;
	text-decoration: none;
	margin-bottom: 5px;
	letter-spacing: 1px;
	margin-left: 15px;
	@include px1024 {
		font-size: 14px;
		white-space: nowrap;
	}

	@include px600 {
		font-size: 12px;
	}
}

.header__basket {
	width: 106px;
	height: 110px;
	background-color: $blue;
	position: relative;
	margin-left: 20px;
	cursor: pointer;
	&:after {
		content: url("../images/shopping-cart.png");
		width: 30px;
		height: 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);


	}

	@include px768 {
		height: 55px;
		width: 90px;
		min-width: 55px;
		max-width: 55px;
	}

	@include px420 {
		margin-left: 0;
		border-left: 1px solid white;
	}

	&>.basket__col {
		position: absolute;
		width: 16px;
		height: 16px;
		min-height: 16px;
		min-width: 16px;
		background-color: $red;
		border-radius: 50%;
		display: flex;
		align-self: center;
		align-content: center;
		align-items: center;
		justify-content: center;
		font-family: 'latobold';
		color: white;
		font-size: 11px;
		left: 50%;
		top: 50%;
		transform: translate(0,-20px);
		z-index: 999;
	}
}


.logo2 {
	transform: skew(-20deg);
	background-color: $blue;
	padding: 25px 16px 25px 18px;
	margin-right: 20px;
	display:inline-block;
	//flex: 1;
	//width: 100%;
	& span {
		display: block;
		transform: skew(20deg);
	}

	@include px768 {
		padding: 8px 16px 8px 18px;
	}

	@include px420 {
		transform: skew(0deg);
		margin-right: 0;
		//width: calc(100% - 110px);
		width: 100%;
		padding: 8px 16px 5px 16px;
		& span {
			transform: skew(0deg);
		}
	}
}

.header__burger {
	display: none;
	min-width: 55px;
	height: 55px;
	background-color: $red;
	padding-top: 10px;
	cursor: pointer;
	&>.line {
		width: 50%;
		height: 1;
		border: 1px solid white;
		margin-bottom: 5px;
	}

	@include px768 {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		
	}
}

.burger__title {
	font-family: 'latobold';
	color: white;
	font-size: 10px;
	text-transform: uppercase;
}

.mm {
	position: absolute;
	left: calc(100% + 80px);
	top: 0;
	background-color: #fff;
	z-index: 99999999999999999999999999999999999999999999999;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 30px 20px;
	width: 320px;
	max-width: 240px;
	&_show {
		left: 100%;
		transform: translateX(-100%);;
	}

	&__close {
		position: absolute;
		left: -80px;
		top: 0;
		width: 80px;
		height: 80px;
		background-color: $red;
		cursor: pointer;
		&>span {
			font-family: 'russo_oneregular';
			font-size: 44px;
			color: white;	
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			
		}

		@include px420 {
			width: 50px;
			height: 50px;
			left: -50px;
		}
	}
}

.mm__title {
	font-family: "russo_oneregular";
	color: #1c4aa9;
	font-size: 38px;
	text-transform: uppercase;
	letter-spacing: 15px;
	text-align: center;
}

.mm__subtitle {
	font-family: "latoregular";
	color: black;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 12px;
	text-align: center;
	margin-bottom: 10px;
}

.mm__text {
	text-align: center;
	font-family: 'latoregular';
	font-size: 14px;
	color: #9091a2;
}

.mm__adress {
	margin-bottom: 20px;
}

.mm__adress-text {
	font-family: 'latoregular';
	font-size: 14px;
	color: #1c1e29;
	margin-right: 20px;
}

.mm__adress-url {
	font-family: "latohairline";
	font-size: 12px;
	text-decoration: none;
	color: #1c4aa9;
}

.mm__items {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid #edeff3;
	border-bottom: 1px solid #edeff3;
	padding: 20px 0;
	margin-bottom: 20px;
}

.mm__url {
	font-family: 'latobold';
	font-size: 16px;
	color: #2b2d42;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-decoration: none;
	text-align: center;
	position: relative;
	&_red {
		color: $red;
	}
}

.mm__subitem {
	&:after {
		content: '\f2f2';
		font-family: 'Material-Design-Iconic-Font';
		position: absolute;
		left: calc(100% + 10px);
		top: 0;
		color: #1c4aa9;
		//transform: translate(-100%,0);
	}

	&_check:after {
		content: '\f2f6';
		color: $red;
	}
}

.mm__job-title {
	font-family: 'latoregular';
	font-size: 18px;
	color: #1c4aa9;
}

.mm__job-text {
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;	
}

.mm__mail {
	font-family: 'latoregular';
	font-size: 14px;
	color: #2b2d42;	
	border-bottom: 1px dashed black;
	margin-bottom: 15px;
	margin-top: 20px;
}

.mm__phone {
	font-family: 'russo_oneregular';
	font-size: 22px;
	color: #2b2d42;	
	margin-bottom: 20px;
}

.mm__button_skew {
	display: inline;
	background-color: $red;
	transform: skewX(-15deg);
	padding: 10px 20px;
	cursor: pointer;
	transition: .2s;
	&:hover {
		background-color: $darkblue;
		transition: .2s;
	}
	&>span {
		display: block;
		font-family: 'russo_oneregular';
		font-size: 14px;
		color: white;	
		transform: skewX(15deg);
		text-transform: uppercase;
	}
}

.mm__subitems--items {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	// margin-left: 20px;
	display: none;
}

.mm__subitem--title {
	font-family: 'latobold';
	font-size: 14px;
	color: #1c4aa9;
	text-decoration: none;
	text-transform: uppercase;
	margin-bottom: 15px;
	&:before {
		content: '\f2ff';
		margin-right: 10px;
	}
}

.mm__subitem--url {
	font-family: 'latobold';
	font-size: 14px;
	color: $red;
	text-decoration: none;
	text-transform: uppercase;	
	margin-bottom: 15px;
}

.arrow-right:after {
	content: '\f2f6';
}

.arrow-down:after {
	content: '\f2f2';
}

.arrow-up:after {
	content: '\f2f8';
	color: $red;
}

.idShow {
	display: none;
}