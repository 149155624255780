.repair {
	width: 100%;
	margin-top: -97px;
	margin-bottom: 50px;
	box-shadow: 0px 8px 40px 0px rgba(35, 36, 42, 0.2);
}

.repair__title {
	display: block;
	width: 100%;
	text-align: center;
	background-color: $red;
	padding: 20px 0;
	font-family: 'russo_oneregular';
	font-size: 24px;
	color: white;
	text-transform: uppercase;
}

.repair__form {
	background-color: #fff;
	background-image: url('../images/repair_bg.png');
	background-position: 0 100%;
	padding: 31px 0;
	
}

.repair__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 822px;
	margin: 0 auto;

	@include px900 {
		width: 100%;
	}

	@include px767 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 10px;
	}
}

.repair__inputs {
	margin-right: 22px;

	@include px767 {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
		padding: 10px;
	}
}

.repair__input {
	display: block;
	background-color: #f4f5f7;
	outline: none;
	border: 1px solid #dddee3;
	padding: 15px 20px;
	font-family: 'latoitalic';
	font-size: 14px;
	color: #9091a2;
	width: 400px;
	&:nth-of-type(1) {
		margin-bottom: 13px;
	}

	@include px900 {
		width: 100%;
	}
}

.repair__textarea {
	background-color: #f4f5f7;
	outline: none;
	border: 1px solid #dddee3;
	padding: 15px 20px;
	font-family: 'latoitalic';
	font-size: 14px;
	color: #9091a2;
	height: 108px;	
	width: 400px;
	resize: none;

	@include px767 {
		width: 100%;


	}
}

.repair__bottom {
	width: 822px;
	margin: 30px auto 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;

	@include px900 {
		width: 100%;
		padding: 0 20px;
	}

	@include px600 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

}

.repair__checkbox_fake {
	width: 16px;
	height: 16px;
	border: 1px solid #dddee3;
	position: relative;
	margin-right: 10px;
}

.repair__checkbox {
	width: 1px;
	height: 1px;
	position: absolute;
	left: 8px;
	top: 28px;
	background-color: transparent;
	border: 1px solid transparent;
	outline: none;
	color: transparent;
	z-index: -1;
	visibility: hidden;
}

.repair__label {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.repair__checkbox:checked~.repair__label>.repair__checkbox_fake:after {
	content: '\f26b';
	font-family: 'Material-Design-Iconic-Font';
	color: red;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

.repair__pers {
	font-family: 'latoregular';
	font-size: 12px;
	color: #9091a2;
	text-transform: uppercase;
	&>a {
		color: #1c4aa9;
	}
}

.repair__submit {
	font-family: 'latobold';
	font-size: 14px;
	background-color: $blue;
	color: white;
	width: 400px;
	text-transform: uppercase;
	padding: 15px 0;
	border: 1px solid $blue;
	cursor: pointer;
	transition: .2s;
	&:hover {
		transition: .2s;
		background-color: $red;
	}

	@include px600 {
		width: 200px;
	}
}

.repair__job_wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid #edeff3;
	padding-bottom: 50px;
	margin-bottom: 50px;

	@include px768 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 5px;
	}
}

.repair__maintitle {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: $darkblue;

	@include px767 {
		font-size: 18px;
	}
}
.repair__jobs {
	width: calc(100% - 24px / 2);
	&:nth-of-type(1) {
		margin-right: 24px;
		@include px768 {
			margin-right: 0;
			width: 100%;
		}		
	}
	@include px768 {
		margin-right: 0;
		width: 100%;
	}	


	
}

.repair__job {
	width: 100%;
	padding: 13px 25px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	&:nth-of-type(odd) {
		background-color: #edeff3;
	}
}

.repair__subject {
	width: 70%;
	display: block;


}

.repair__subject,.repair__price {
	font-family: 'latoregular';
	font-size: 16px;
	color: #2b2d42;

	@include px767 {
		font-size: 12px;
	}
}