.content {
	width:1180px;
	margin: 40px auto 0 auto;
	word-wrap: break-word;
	&>p {
		word-wrap: break-word;
	}
	@include px1180 {
		width: 100%;
		max-width: 1240px;
		padding: 0 10px;
	}

	font-family: 'latoregular';
}

.content__items {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;

	@include px768 {
		justify-content: flex-start;
		align-items: center;
	}
}

.content__item {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	position: relative;
	// z-index: 1;
	&:nth-of-type(2n) {
			flex-direction: row-reverse;
			@include px768 {
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
			}			
	}
	margin-bottom: 100px;

	@include px767 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 40px;
	}
}

.content__image {
	width: 50%;
	min-width: 50%;
	max-width: 50%;
	background-size: contain;
	background-position: 0 0;
	background-repeat: no-repeat;
	z-index: 999;
	position: relative;

	@include px768 {
		width: 320px;
		min-width: 320px;
		max-width: 320px;
		height: 320px;
		order: 2;
		border: 1px solid black;
		margin-top: 30px;
	}
}

.content__info {
	z-index: 99999;

	@include px768 {
		order: 1;
	}
}

.content__title {
	font-family: 'russo_oneregular';
	font-size: 36px;
	color: $darkblue;
	z-index: 999999999;

	@include px767 {
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
	}

}

.content__item:nth-of-type(2n+1)>.number-bg {
	transform: translateX(-250px);

}

.content__item {
	&:nth-of-type(1)>.number-bg:after {
		content: '1';
	}

	&:nth-of-type(2)>.number-bg:after {
		content: '2';
	}

	&:nth-of-type(3)>.number-bg:after {
		content: '3';
	}
	
	&:nth-of-type(4)>.number-bg:after {
		content: '4';
	}
	
	&:nth-of-type(5)>.number-bg:after {
		content: '5';
	}
	
	&:nth-of-type(6)>.number-bg:after {
		content: '6';
	}
	
	&:nth-of-type(7)>.number-bg:after {
		content: '7';
	}
	
	&:nth-of-type(8)>.number-bg:after {
		content: '8';
	}	
}
.number-bg {
	position: absolute;
	left: 50%;
	top: 0;
	&:after {
	position: absolute;
	left: 50%;
	top: -100px;
	font-family: 'geometriaextrabold';
	font-size: 600px;
	margin: 0;
	padding: 0;
	color: white;
	// transform: translateX(-50%);
	text-shadow: 4px 0px 15px rgba(89,89,89,0.05), -4px 0px 15px rgba(89,89,89,0.05), 0px -4px 15px rgba(89,89,89,0.05), 0px 4px 15px rgba(89,89,89,0.05);
	}

}

.content__item>.number-bg {

	@include px767 {
		display: none;
	}
	
}

.content__image>.number-bg {
	font-family: 'geometriaextrabold';
	font-size: 250px;
	margin: 0;
	padding: 0;
	color: white;
	left: 100%;
	top: -40px;
	transform: translateX(-100%);
	text-shadow: 4px 0px 15px rgba(89,89,89,0.05), -4px 0px 15px rgba(89,89,89,0.05), 0px -4px 15px rgba(89,89,89,0.05), 0px 4px 15px rgba(89,89,89,0.05);		
	z-index: 998;
	display: none;
	@include px767 {
		display: block;
		z-index: 998;
	}
}



.content__description {
	font-family: 'latoregular';
	font-size: 18px;
	color: $dark;
	line-height: 1.333;

	@include px767 {
		font-size: 14px;
	}
}

.news {
	width: 1180px;
	margin: 0 auto 60px auto;
	position: relative;

	@include px1180 {
		width: 100%;
		padding: 0 10px;
	}
}

.news__titles {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.news__items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	list-style: none;
	margin: 0;
	padding: 0;	
	
	@include px768 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}
}

.news__item {
	margin: 0;
	padding: 0;
	width: 395px;
	border: 1px solid #edeff3;

	@include px768 {
		width: 100%;
		margin: 10px;
	}

}

.news__url {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-decoration: none;
	position: relative;
	padding: 10px 10px 30px 10px;
	transition: box-shadow .2s;
	// padding-bottom: 30px;	
	&:after {
		content: '\f2ee';
		position: absolute;
		left: calc(100%);
		top: 100%;
		transform: translate(-100%,-100%);
		// width: 50px;
		// height: 50px;
		padding: 12px 18px;
		background-color: $bluebg;
    font-family: 'Material-Design-Iconic-Font';
		font-size: 12px 18px;		
		color: white;
		transition: background-color .2s;
	}
	&:hover {
		box-shadow: 0px 14px 22px 0px rgba(167, 172, 182, 0.3);
		transition: box-shadow .2s;
		&>.news__title-small {
			transition: color .2s;
			color: $red;
		}
		&:after {
			background-color: $red;
			transition: background-color .2s;
		}
	}
}

.news__icon {
	width: 100%;
	height: 260px;
	background-size: cover;
	background-position: 0;
	background-repeat: no-repeat;
}

.news__date {
	font-family: 'latoregular';
	font-size: 14px;
	color: $graytext;
	margin-bottom: 0;
}

.news__title-small {
	font-family: 'latobold';
	font-size: 20px;
	color: $darkblue;	
  font-weight: bold;
  text-transform: uppercase;
	line-height: 1.5;
	margin-top: 28px;
	transition: color .2s;
}

.content__info {
	@include px767 {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}
.content__info>.link_skew {
@include px767 {
	margin-bottom: 10px;
}
}