.model__infos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	margin-bottom: 40px;

	@include px1024 {
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
	}



}

.model__info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	width: calc(100% - 20px);

	@include px1024 {
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
		width: 100%;
	}
}

.model__info2 {
	width: 500px;
	min-width: 500px;
	@include px600 {
		width: 100%;
		min-width: 100%;
	}
}

.model__title {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: $darkblue;

}

.model__text {
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;
	font-weight: normal;
	line-height: 1.3;
}

.model__price {
	font-family: 'latobold';
	font-size: 20px;
	color: white;
	padding: 16px 20px;
	background-color: $red;
	text-transform: uppercase;
	text-align: center;
	position: absolute;
	bottom: 0;
	margin-bottom: 0;
	width: calc(100% - 20px);

	@include px1240 {
		font-size: 16px;
		padding: 17px 10px;
	}

	@include px1024 {
		position: relative;
	}
}

.model__img {
	width: 100%;
	height: 440px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 15px;
}

.model__get {
	font-family: 'latobold';
	font-size: 16px;
	color: white;
	padding: 17px 0px 17px 20px;
	background-color: $darkblue;
	text-transform: uppercase;
	width: 100%;
	display: block;
	position: relative;
	cursor: pointer;
	transition: .2s;
	&:hover {
		background-color: $red;
		transition: .2s;
	}
	&:after {
		content: '\f301';
		font-family: 'Material-Design-Iconic-Font';
		font-size: 24px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		border-left: 1px solid white;
		//height: 100%;
		padding: 13px 12px 12px 12px;
		background-color: $red;
	}

	@include px600 {
		font-size: 12px;
		padding: 13px 5px; 
	}
}

.model__menu-wrapper {
	width: 100%;
	background-color: #edeff3;
	padding: 50px 0;
}

.model__menu-items {
	width: 1180px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 8px 22px 0px rgba(35, 36, 42, 0.17);

	@include px1180 {
		width: 100%;
		padding: 5px;
	}

	@include px767 {
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
	}
}

.model__menu-url {
	font-family: 'russo_oneregular';
	color: #2b2d42;
	background-color: #fff;
	font-size: 18px;
	padding: 31px 0;
	text-align: center;
	text-transform: uppercase;
	// width: 280px;
	// min-width: 280px;
	width: 20%;
	display: block;
	border-right: 1px solid #edeff3;
	transition: .2s;
	&:hover {
		background-color: $red;
		color: white;
		transition: .2s;
	}
	&_active {
		background-color: $red;
		color: white;

	}

	@include px767 {
		width: 100%;
	}
}

.model__goods {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 20px;


}

.model__good {
	width: 550px;
	border: 1px solid #edeff3;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 20px;

	@include px600 {
		width: 100%;
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
	}
}

.model__good-img {
	width: 183px;
	min-width: 183px;;
	height:	156px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	border-right: 1px solid #edeff3;

	@include px600 {
		width: 100%;
	}
}

.model__good-icon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

.model__good-info {
	padding: 16px 23px;
}
.model__good-title {
	font-family: 'latobold';
	font-size: 14px;
	color: $darkblue;
	text-transform: uppercase;
	display: block;
}

.model__good-text {
	font-family: 'latoregular';
	font-size: 14px;
	color: #2b2d42;	
}

.gallery__items {
	margin-bottom: 20px;
	&>.slick-prev {
		width: 40px;
		height: 40px;
		background-color: $red;
		z-index: 999;
		left: 0;
		&:before {
			content: '\f2fa';
			font-family: 'Material-Design-Iconic-Font';
			font-size: 28px;
		}
	}

	&>.slick-next {
		width: 40px;
		height: 40px;
		background-color: $red;
		z-index: 999;
		right: 0;
		&:before {
			content: '\f2fb';
			font-family: 'Material-Design-Iconic-Font';
			font-size: 28px;
		}
	}	
}

.gallery__item {
	//width: 380px;
	max-width: 380px;
	height: 275px;
	margin-right: 24px;
	//border-right: 24px solid white;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.komplect__items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	margin: 20px 0;
	flex-wrap: wrap;

	@include px600 {
		justify-content: center;
	}
}

.komplekt__item {
	background-color: #edeff3;
	background-image: url('../images/komplekt_bg.png');
	background-repeat: no-repeat;
	background-position: right bottom;
	width: 32%;
	padding: 56px 32px 56px 48px;
	min-width: 337px;
	
}

.komplect__text {
	display: block;
	font-family: 'latobold';
	font-size: 20px;
	color: $darkblue;
	margin-bottom: 30px;
	letter-spacing: 2px;
	text-indent: -25px;
	&:before {
		content: '\f26b';
    font-family: 'Material-Design-Iconic-Font';
    color: #f20732;
    margin-right: 5px;		
	}
}

.ready__items {
	margin: 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;

	@include px600 {
		justify-content: center;
	}

	&>a {
		width: 290px;
		//height: 190px;
		@include px600 {
			width: 100%;
		}
	}
	
}

.ready__item {
	background-color: $darkblue;
	cursor: pointer;
	transition: .2s;
	text-decoration: none;
	&:hover {
		background-color: $red;
		transition: .2s;
	}

	@include px600 {
		width: 100%;
	}
}

.ready__img {
	width: 290px;
	height: 190px;
	border-bottom: 1px solid white;
	margin-bottom: 15px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@include px600 {
		width: 100%;
		display: block;
	}
}

.ready__title {
	font-family: 'russo_oneregular';
	font-size: 13px;
	color: white;
	display: block;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
}

.ready__text {
	font-family: 'latoregular';
	font-size: 11px;
	color: white;
	text-align: center;
}

.ready__table {
	margin-top: 20px;
}