.menu-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	//align-items: stretch;

	@include px767 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

.leftmenu {
	margin-right: 40px;
	// width: 380px;
	// background-color: red;
	background-color: #edeff3;
	// padding: 10px 20px;
	cursor: pointer;

	@include px767 {
		width: 100%;
	}
}

.leftmenu__title {
	width: 100%;
	display: block;
	background-color: #08234a;
	text-align: center;
	padding: 10px 20px;
	margin: 0;
	font-family: 'russo_oneregular';
	font-size: 22px;
	color: white;
	white-space: nowrap;
}

.leftmenu__items {
	list-style: none;
	margin: 0;
	padding: 10px 20px;
}

.leftmenu__item {
	cursor: pointer;
	margin: 30px 0 0px 0;
	position: relative;
	// display: flex;
	// flex-direction: row;
	// justify-content: space-between;
	// align-items: center;
}

.leftmenu__item-title {
	
	display: inline;
	font-family: 'latobold';
	font-size: 18px;
	color: $darkblue;
	text-transform: uppercase;
	cursor: pointer;
	position: absolute;
	left: 100%;
	top: 10px;
	transform: translate(-100%,0);
	white-space: nowrap;
	&:after {
		content: '\f2fc';
		font-family: 'Material-Design-Iconic-Font';
		color: $darkblue;
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-100%,-50%);
	}
	
}

.leftmenu__url {
	text-decoration: none;
	font-family: 'latobold';
	font-size: 12px;
	color: $darkblue;
	text-transform: uppercase;
	cursor: pointer;
	width: 100%;
	display: block;
	position: relative;
	padding-right: 20px;
	white-space: nowrap;	
}

.leftmenu__item-checkbox {
	position: absolute;
	width: 0;
	height: 0;
	visibility: hidden;
}
.leftmenu__item>.leftmenu__subitems {
	display: none;
}
.leftmenu__item>input:checked~.leftmenu__subitems {
	display: block;
}

.leftmenu__item>input:checked~.leftmenu__item-title:after {
	content: '\f2f9';
	font-family: 'Material-Design-Iconic-Font';
	color: $red;
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translate(-100%,-50%);
}

.leftmenu__subitems {
	list-style: none;
	margin-left: 0;
	padding-left: 20px;
}

.leftmenu__suburl {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.leftmenu__subitem-title {
	font-family: 'latoregular';
	font-size: 14px;
	color: #6e6f81;
}

// .menu-content>.content-article {
// 	min-width: 348px;
// }

.active__li {
	text-decoration: underline;
}