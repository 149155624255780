/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 2, 2019 */



@font-face {
    font-family: 'geometriamedium';
    src: url('../fonts/geometria-medium.woff2') format('woff2'),
         url('../fonts/geometria-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriamedium_italic';
    src: url('../fonts/geometria-mediumitalic.woff2') format('woff2'),
         url('../fonts/geometria-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriathin';
    src: url('../fonts/geometria-thin.woff2') format('woff2'),
         url('../fonts/geometria-thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriathin_italic';
    src: url('geometria-thinitalic.woff2') format('woff2'),
         url('geometria-thinitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}