@import 'https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css';
@import '//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css';
@import './includes/vars.scss';
@import './includes/normalize.scss';
@import './includes/media.scss';
@import './includes/general.scss';
@import './includes/slick-theme.scss';
@import './includes/magnific-popup.scss';


@import '../fonts/stylesheet_font1.scss';
@import '../fonts/stylesheet_font2.scss';
@import '../fonts/stylesheet_font3.scss';
@import './includes/iconfont.scss';

// Блоки
@import './sections/1header.scss';
@import './sections/2topmenu.scss';
@import './sections/3slider.scss';
@import './sections/3undermenu.scss';
@import './sections/4menu.scss';
@import './sections/5logos.scss';
@import './sections/6contacts.scss';
@import './sections/7footer.scss';
@import './sections/material_list.scss';

@import './sections/topheader.scss';
@import './sections/menu_icon.scss';
@import './sections/model_car_item.scss';
@import './sections/leftmenu.scss';

// Страницы
@import './pages/index.scss';
@import './pages/parts_main.scss';
@import './pages/parts_subdivision.scss';
@import './pages/part_page.scss';
@import './pages/tech_stock.scss';
@import './pages/repair_record.scss';
@import './pages/separator.scss';
@import './pages/model.scss';
@import './pages/device.scss';

@import './sections/slider.scss';


