.contacts {
	width: 1180px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	@include px1180 {
		width: 100%;
		padding: 10px 20px;
	}

	@include px1024 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	
	}
}

.contacts__info {
	width: 50%;
	min-width: 50%;
	max-width: 50%;
	
	@include px1024 {
		width: 100%;
		min-width: 100%;
		padding: 20px;
	}
}

.contact__title {
	font-family: 'russo_oneregular';
	font-size: 36px;
	color: #102858;
	margin-top: 0;

	@include px767 {
		font-size: 24px;
	}
}

.contact__map {
	width: 50%;
	min-width: 50%;
	max-width: 50%;	

	@include px1024 {
		width: 100%;
		min-width: 100%;
	}
}

.contact__adress {
	font-family: 'russo_oneregular';
	font-weight: 100;
	font-size: 17px;
	color: black;

	@include px767 {
		font-size: 16px;
	}
}

.contact__email {
	font-family: 'russo_oneregular';
	font-size: 17px;
	color: black;	
	@include px767 {
		font-size: 16px;
	}	
}

.contact__subject {
	font-family: 'russo_oneregular';
	font-size: 17px;
	color: black;
	@include px767 {
		font-size: 16px;
	}	
}

.contact__phone {
	font-family: 'latoregular';
	font-size: 22px;
	color: black;	
	text-decoration: none;
	@include px767 {
		font-size: 18px;
	}	
}