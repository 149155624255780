/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 2, 2019 */



@font-face {
    font-family: 'geometriaregular';
    src: url('../fonts/geometria.eot');
    src: url('../fonts/geometria.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria.woff2') format('woff2'),
         url('../fonts/geometria.woff') format('woff'),
         url('../fonts/geometria.ttf') format('truetype'),
         url('../fonts/geometria.svg#geometriaregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriabold';
    src: url('../fonts/geometria-bold.eot');
    src: url('../fonts/geometria-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-bold.woff2') format('woff2'),
         url('../fonts/geometria-bold.woff') format('woff'),
         url('../fonts/geometria-bold.ttf') format('truetype'),
         url('../fonts/geometria-bold.svg#geometriabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriabold_italic';
    src: url('../fonts/geometria-bolditalic.eot');
    src: url('../fonts/geometria-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-bolditalic.woff2') format('woff2'),
         url('../fonts/geometria-bolditalic.woff') format('woff'),
         url('../fonts/geometria-bolditalic.ttf') format('truetype'),
         url('../fonts/geometria-bolditalic.svg#geometriabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaextrabold';
    src: url('../fonts/geometria-extrabold.eot');
    src: url('../fonts/geometria-extrabold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-extrabold.woff2') format('woff2'),
         url('../fonts/geometria-extrabold.woff') format('woff'),
         url('../fonts/geometria-extrabold.ttf') format('truetype'),
         url('../fonts/geometria-extrabold.svg#geometriaextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaextrabold_italic';
    src: url('../fonts/geometria-extrabolditalic.eot');
    src: url('../fonts/geometria-extrabolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-extrabolditalic.woff2') format('woff2'),
         url('../fonts/geometria-extrabolditalic.woff') format('woff'),
         url('../fonts/geometria-extrabolditalic.ttf') format('truetype'),
         url('../fonts/geometria-extrabolditalic.svg#geometriaextrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaextralight';
    src: url('../fonts/geometria-extralight.eot');
    src: url('../fonts/geometria-extralight.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-extralight.woff2') format('woff2'),
         url('../fonts/geometria-extralight.woff') format('woff'),
         url('../fonts/geometria-extralight.ttf') format('truetype'),
         url('../fonts/geometria-extralight.svg#geometriaextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaextralight_italic';
    src: url('../fonts/geometria-extralightitalic.eot');
    src: url('../fonts/geometria-extralightitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-extralightitalic.woff2') format('woff2'),
         url('../fonts/geometria-extralightitalic.woff') format('woff'),
         url('../fonts/geometria-extralightitalic.ttf') format('truetype'),
         url('../fonts/geometria-extralightitalic.svg#geometriaextralight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaheavy';
    src: url('../fonts/geometria-heavy.eot');
    src: url('../fonts/geometria-heavy.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-heavy.woff2') format('woff2'),
         url('../fonts/geometria-heavy.woff') format('woff'),
         url('../fonts/geometria-heavy.ttf') format('truetype'),
         url('../fonts/geometria-heavy.svg#geometriaheavy') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaheavy_italic';
    src: url('../fonts/geometria-heavyitalic.eot');
    src: url('../fonts/geometria-heavyitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-heavyitalic.woff2') format('woff2'),
         url('../fonts/geometria-heavyitalic.woff') format('woff'),
         url('../fonts/geometria-heavyitalic.ttf') format('truetype'),
         url('../fonts/geometria-heavyitalic.svg#geometriaheavy_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometriaitalic';
    src: url('../fonts/geometria-italic.eot');
    src: url('../fonts/geometria-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-italic.woff2') format('woff2'),
         url('../fonts/geometria-italic.woff') format('woff'),
         url('../fonts/geometria-italic.ttf') format('truetype'),
         url('../fonts/geometria-italic.svg#geometriaitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometrialight';
    src: url('../fonts/geometria-light.eot');
    src: url('../fonts/geometria-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-light.woff2') format('woff2'),
         url('../fonts/geometria-light.woff') format('woff'),
         url('../fonts/geometria-light.ttf') format('truetype'),
         url('../fonts/geometria-light.svg#geometrialight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geometrialight_italic';
    src: url('../fonts/geometria-lightitalic.eot');
    src: url('../fonts/geometria-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geometria-lightitalic.woff2') format('woff2'),
         url('../fonts/geometria-lightitalic.woff') format('woff'),
         url('../fonts/geometria-lightitalic.ttf') format('truetype'),
         url('../fonts/geometria-lightitalic.svg#geometrialight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}