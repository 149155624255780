.menu-wrapper {
	background-color: $graybg;
	width: 100%;
}
.menu {
	position: relative;
	width: 1180px;
	margin: -80px auto 0 auto;
	padding-bottom: 0px;
	z-index: 999999999;
	max-width: 100%;

	@include px1024 {
		width: 100%;
	}

	@include px1024 {
		margin-top: -100px;
		padding: 10px;
	}
}

.menu__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	box-shadow: 0px 8px 40px 0px rgba(35, 36, 42, 0.2);
	margin: 0;
	padding: 0;
	list-style: none;
	@include px767 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

.menu__item {
	width: 248px;
	height: 160px;
	background-color: white;
	transition: background-color .3s;
	&:hover {
		background-color: $red;
		transition: background-color .3s;
	}
	&:hover>.menu__url>.menu__icon {
		color: white;
		transition: color .3s;
	}
	&:hover>.menu__url>.menu__title {
		color: white;
		transition: color .3s;
	} 

	@include px767 {
		width: 100%;
		padding: 10px 20px;
		height: auto;
		border-bottom: 1px solid #edeff3;
	}
}

.menu__url {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	height: 100%;

	@include px767 {
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	@include px767 {
		width: 100%;
	}
}

.menu__icon {
	font-family: 'icomoon';
	color: $red;
	font-size: 54px;
	transition: color .3s;
}

.menu__title {
	font-family: 'russo_oneregular';
	font-size: 18px;
	color: $dark;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0;
	transition: color .3s;
	@include px1024 {
		font-size: 12px;
	}

	@include px767 {
		margin-left: 20px;
		text-align: left;
	}
}