.undermenu {
	width: 100%;
	height: 215px;
	padding: 28px 0 37px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@include px1240 {
		padding:	10px;
	}	
}

.undermenu-wrapper {
	width: 1180px;
	margin: 0 auto;
	padding: 0;

	@include px1180 {
		width: 100%;
	}
}

.breadcrumbs {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.breadcrumb {
	&:after {
		content: '|';
		font-family: 'latoregular';
		font-size: 14px;
		color: white;
		margin-left: 5px;
		margin-right: 5px;
	}
	&:nth-last-of-type(1):after {
		content: '';
	}
	&:nth-last-of-type(1)>.breadcrumb__url {
		text-decoration: none;

	}
}

.breadcrumb__url {
	font-family: 'latoregular';
	font-size: 14px;
	color: white;
}

