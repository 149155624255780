@mixin px1280 {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin px1240 {
    @media screen and (max-width: 1240px) {
        @content;
    }
}

@mixin px1180 {
    @media screen and (max-width: 1180px) {
        @content;
    }
}

@mixin px1024 {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin px900 {
    @media screen and (max-width: 900px) {
        @content;
    }
}

@mixin px768 {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin px767 {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin px600 {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin px420 {
    @media screen and (max-width: 420px) {
        @content;
    }
}