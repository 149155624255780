* {
	box-sizing: border-box;
}

body {
	overflow-x: hidden;;
}
.wrapper {
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

.button_skew {
	position: relative;
	background-color: transparent;
	border: 1px solid transparent;
	 min-width: 200px;
	// min-height: 35px;
	cursor: pointer;
	&>.button__bg {
		width: 100%;
		height: 100%;
		transform: skewX(-20deg);
		padding: 12px 15px;
	}

	&>.button__title {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-family: 'latobold';
		color: white;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
}	

.button_red>.button__bg {
	background-color: $red;
}

// .button_blue>.button__bg {
// 	background-color: $blue;
// }

/* 
Кнопки сохдаются таким образом:

				button.button.button_skew.button_red
					.button__bg
					span.button__title акция до 31 мая

*/

.button_blue {
	background-color: $blue;
	padding: 16px 40px 16px 45px;
	color: white;
	font-family: 'latobold';
	font-size: 14px;
	border: none;
	outline: none;
	text-transform: uppercase;
	letter-spacing: 3px;
	transition: background-color .2s;
	&:hover {
		background-color: $red;
		transition: background-color .2s;
	}
}

.buttn_arrow {
	text-decoration: none;
	&:after {
		content: '\f2ee';
		margin-left: 15px;
		font-family: 'Material-Design-Iconic-Font';
		font-size: 14px;
		color: white;
	}
}

.hr {
	width: 100%;
	height: 1px;
	border: 1px solid $blue;
}

/*
	Стилизация ссылок
*/

.header__phones>.link_skew {
	font-size: 12px;
	@include px1024 {
		font-size: 9px;
	}
}

.link_skew {
	padding: 8px 20px;
	white-space: nowrap;
	position: relative;
	z-index: 999;
	text-decoration: none;
	color: white;
	font-family: 'latobold';
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	&:before {
		content:'';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: $red;
		transform: skewX(-20deg);
		transition: background-color .2s;
		z-index: -1;
	}
	&:hover:before {
		transition: background-color .2s;
		background-color: $darkblue;
	}

	@include px1024 {
		padding: 8px 10px;
		letter-spacing: 1px;
	}
}



.link_arrow:after {
	content: '\f2ee';
	margin-left: 10px;
	font-family: 'Material-Design-Iconic-Font';
	font-size: 14px;
	color: white;

	@include px1024 {
		display: none;
	}
}

.ajaxSearch_resultLink {
	font-family: 'Material-Design-Iconic-Font';
	font-size: 14px;
	
}

.ajaxSearch_resultExtract>p {
	font-family: 'Material-Design-Iconic-Font';
	font-size: 14px;	
}

.findform {
	width: 100%;
}

.link_white {
	color: $red;
	&:before {
		background-color: white;
	}
	&:after {
		color: $red;
	}
}

.title_small {
	font-family: 'russo_oneregular';
	font-size: 36px;
	color: #102858;
	text-transform: uppercase;
}



.wrapper_small {
	width: 1240px;
}

.h1 {
	font-family: 'russo_oneregular';
	font-size: 30px;
	color: white;
	text-transform: uppercase;
	letter-spacing: 1.3px;
}

/*
Стили редактора
*/

.content-article {
	font-family: 'latoregular';
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 40px;	&>ul {
		list-style-type: none;
	}
	&>ul li:before {
			content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: red; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
	}

}

.hide {
	display: none;
}

.content-right {
	width: 100%;
}

.add-basket {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.if:after,.if:before {
	font-family: 'Material-Design-Iconic-Font';
}

.showitflex {
	display: flex !important;
}

.popup {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(white, 0.99);
	z-index: 99999999999999999999999999999999999999999999999999999999999999999999999;
	// display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	&__close {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-20px,20px);
		&:after {
			content: '\f136';
			font-size: 64px;
			font-weight: bold;
			color: $red;
			cursor: pointer;
		}
	}
	&>.repair__form {
		padding: 20px;
		background-color: transparent;
		background-image: none;
		@include px767 {
			width: 100%;
		}
		&>.repair__bottom {
			&>.repair__submit {
				@include px767 {
					margin-top: 10px;
					width: 100%;
				}
			}
			@include px767 {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			}
		}
	}
}

.basket {
	display: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 9999999999999999999999999999999999999999;
	background-color: rgba(#FFF, 0.95);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: "latoregular";
	font-size: 16px;
	&__table {
		width: 90%;
		border-collapse: collapse;
		& tr {
			&:nth-of-type(1) {
				font-size: 24px;
			}
			margin-bottom: 10px;
			& td {
				border: 1px solid black;
				text-align: center;
				margin-right: 40px;

				@include px600 {
					font-size: 12px;
				}
			}
		}
		}
		// border: 1px solid black;
		&>.repair__form {
			background-color: transparent;
		}
}

.basket>.closeW {
	font-family: 'latobold';
	font-size: 36px;
	color: red;
	border: 2px solid red;
	border-radius: 50%;
	padding: 5px 10px;
	position: absolute;
	left: calc(100% - 100px);
	top: 30px;
	cursor: pointer;
}

.success__text {
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	font-family: 'latobold';
	font-size: 36px;	
	text-align: center;
	z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
}

.containerr {
	display: none;
	position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
	margin: auto;
	z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
}

.basketReset {
	margin-bottom: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.pdelete {
	cursor: pointer;
}

.item {
  width: 100px;
  height: 100px;
  position: absolute;
}

.item-1 {
  background-color: #FA5667;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
          animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #7A45E5;
  top: 0;
  right: 0;
  -webkit-animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
          animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #1B91F7;
  bottom: 0;
  right: 0;
  z-index: 1;
  -webkit-animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
          animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #FAC24C;
  bottom: 0;
  left: 0;
  -webkit-animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
          animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@-webkit-keyframes item-1_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
  50% {-webkit-transform: translate(100px, 100px);transform: translate(100px, 100px)} 
  75% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
}

@keyframes item-1_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
  50% {-webkit-transform: translate(100px, 100px);transform: translate(100px, 100px)} 
  75% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
}

@-webkit-keyframes item-2_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
  50% {-webkit-transform: translate(-100px, 100px);transform: translate(-100px, 100px)} 
  75% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
}

@keyframes item-2_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
  50% {-webkit-transform: translate(-100px, 100px);transform: translate(-100px, 100px)} 
  75% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
}

@-webkit-keyframes item-3_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
  50% {-webkit-transform: translate(-100px, -100px);transform: translate(-100px, -100px)} 
  75% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
}

@keyframes item-3_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
  50% {-webkit-transform: translate(-100px, -100px);transform: translate(-100px, -100px)} 
  75% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
}

@-webkit-keyframes item-4_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
  50% {-webkit-transform: translate(100px, -100px);transform: translate(100px, -100px)} 
  75% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
}

@keyframes item-4_move {
  0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
  25% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
  50% {-webkit-transform: translate(100px, -100px);transform: translate(100px, -100px)} 
  75% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
}

.hidden {
	visibility: hidden;
	width: 0;
	height: 0;
	position: absolute;
}

