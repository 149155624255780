.logos-wrapper {
	background-color: $graybg;
	width: 100%;
}
.logos {
	width: 1180px;
	margin: 0 auto;
	padding-bottom: 30px;
	padding-top: 30px;

	@include px1180 {
		width: 100%;
		padding: 30px 10px;
	}
}

.logo__items {
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@include px1280 {
		flex-wrap: wrap;
	}
}

.logo__item {
	padding: 0 40px;
	&:hover>.logo__url>.logo__img {
		filter: grayscale(0);	
		transition: filter .5s;
	}

	@include px1280 {
		padding: 10px 20px;
	}
}

.logo__img {
	filter: grayscale(100%);
	transition: filter .5s;
}