@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?bie5u3');
  src:  url('../fonts/icomoon.eot?bie5u3#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?bie5u3') format('truetype'),
    url('../fonts/icomoon.woff?bie5u3') format('woff'),
    url('../fonts/icomoon.svg?bie5u3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-truck:before {
  content: "\e900";
}
.icon-oborudovanie:before {
  content: "\e901";
}
.icon-remont:before {
  content: "\e902";
}
.icon-zapchasti:before {
  content: "\e903";
}
.icon-zvonok:before {
  content: "\e904";
}
