.slider__items {
	position: relative;
}

.slider__item {
	position: relative;

	@include px768 {
		height: 500px;
	}
}

.slider__bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.slider__info {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1240px;
	transform: translate(-50%,-50%);

	@include px1280 {
		padding: 0 60px;
		width: 100%;
	}
	
}

.slider__info {
	@include px600 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.slider__title {
	font-family: 'russo_oneregular';
	font-size: 46px;
	color: white;
	text-transform: uppercase;

	@include px1280 {
		font-size: 36px;
	}

	@include px600 {
		text-align: center;
	}

	@include px420 {
		font-size: 24px;
	}

}

.slider__items>.slick-prev {
	position: absolute;
	left: calc(((100% - 1240px) / 2) - 36px);
	top: 50%;
	transform: translate(-100%,-50%);
	z-index: 999999;
	width: 46px;
	height: 46px;
	background-color: rgba(black, 0.7);
	border: none;
	outline: none;
	&:before {
		content: '\f2fa';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		font-family: 'Material-Design-Iconic-Font';
		font-size: 32px;
		color: white;
	}		

	@include px1280 {
		left: 50px;
	}
	@include px420 {
		left: -100px;
	}	
	
}

.slider__items>.slick-next {
	position: absolute;
	left: calc((((100% - 1240px) / 2 ) + 1240px) + 82px);
	top: 50%;
	transform: translate(-100%,-50%);
	z-index: 999999;
	width: 46px;
	height: 46px;
	background-color: rgba(black, 0.7);
	border: none;
	outline: none;
	&:before {
		content: '\f2fb';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		font-family: 'Material-Design-Iconic-Font';
		font-size: 32px;
		color: white;
	}		

	@include px1280 {
		left: calc(100% - 5px);		
	}

	@include px420 {
		left: -100px;
	}
}

.slider__items>.slick-dots {
	position: absolute;
	left: 50%;
	bottom: 84px;
	transform: translate(-50%,0);
	z-index: 9999;
	&>li {
		width: 40px;
		height: 2px;
		background-color: #b7b9ba;
	}
	&>.slick-active {
		background-color: red;
	}
}