.topmenu {
	width: 1180px;
	margin: 0 auto;	
	@include px1180 {
		width: 100%;
		max-width: 100%;
		padding: 0 20px;
	}

	

	@include px768 {
		display: none;
	}
}

.topmenu__items {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 14px 0 14px 0;
}

.topmenu__item {
	margin: 0;
	padding: 0;

	&:hover {

	}
}

.topmenu__item_sub {
	position: relative;
	&:after {
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(5px,-50%);
		cursor: pointer;
		font-family: 'Material-Design-Iconic-Font';
		content: '';
		color: $blue;
	}
}

.topmenu__title {
	font-family: 'latobold';
	font-size: 16px;
	color: $dark;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		text-shadow: 0px 3px 3px rgba(255,255,255,0.5), 3px 1px 20px #595959;
	}
}

.topmenu__subitems {
	display: none;
	position: absolute;
	padding: 0;
	list-style: 0;	
	transition: display .5s;
	padding-top: 10px;
	border-radius: 10px;
	z-index: 99999999999999999999999;
	//border: 1px solid black;
	box-shadow: 0px 1px 1px rgba(255,255,255,0.5), 3px 1px 20px #595959;
}

.topmenu__subitem {
	background-color: #fff;	
	list-style: none;
	padding: 10px 20px 10px 10px;
	margin: 0;
	&:hover {
		text-shadow: 0px 3px 3px rgba(255,255,255,0.5), 3px 1px 20px #595959;
	}
}

.topmenu__subitem_url {
	text-transform: uppercase;
	text-decoration: none;
}

.topmenu__subitem_url>span {
	white-space: nowrap;
	font-size: bold;
	font-family: 'latobold';
	font-size: 16px;
	color: $dark;	
}

.topmenu__item:hover>.topmenu__title+.topmenu__subitems {
	display: block;
	transition: display .5s;
}

