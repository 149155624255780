.content-subdivision>.menu-content {
	@include px768 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}
.part-find {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.part-find__input {
	padding: 5px 10px;
	margin-right: -10px;
	width: calc(100% - 100px);
	font-family: 'latoregular';
	font-size: 12px;
}

.part-find__buttons {
	position: relative;
	width: 100px;
	height: 36px;
}

.part-find__button  {
	transform: skew(-15deg);
	width: 100px;
	height: 36px;
	position: absolute;
	background-color: $red;
	outline: none;
	border: 1px solid $red;
}

.part-find__button-title {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

// Таблица результатов поиска
.search-result {
	width: 100%;
	margin-top: 30px;
	margin-bottom: 40px;
	@include px768 {
		overflow-x: scroll;
	}
}

.search-result__items {
	width: 100%;
	min-width: 100%;
}

.result__items {
	min-width: 740px;
}

.search-result__item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;	
	margin-bottom: 10px;
	background-color: #edeff3;
	border: 1px solid #dddee3;
	&>a {
		text-decoration: none;
	}
	&>.search__result>a {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-decoration: none;
	}
}

.search__result {
	border-right: 1px solid #dddee3;
	padding: 13px 20px;

	&>a>i {
		font-size: 60px;
		color: black;
		transition: .2s;
		&:hover {
			color: $red;
			cursor: pointer;
			transition: .2s;
		}
		&:hover~.search__img {
			display: block;
		}
	}
	&:nth-of-type(4) {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		flex-wrap: nowrap;
	}
	&:nth-of-type(1) {
		min-width: 220px;
		width: 220px;
		max-width: 220px;
		text-align: left;
	&>a {
		align-items: flex-start;
	}
	}
	&:nth-of-type(2) {
		min-width: 140px;
		width: 140px;
		max-width: 140px;		
	}
}

.search__img {
	display: none;
	position: absolute;
	left: 50%;
	top: 100%;
	width: 200px;
	height: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 999;
}
.search__title {
	font-family: 'latoregular';
	font-size: 12px;
	color: #9091a2;
}

.search__name {
	font-family: 'latobold';
	font-size: 14px;
	color: $darkblue;
	&:nth-of-type(2) {
		overflow-wrap: break-word;  /* не поддерживает IE, Firefox; является копией word-wrap */ 
		word-wrap: break-word;
		word-break: break-all;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */ 
		line-break: auto;  /* нет поддержки для русского языка */ 
		hyphens: manual;  /* значение auto не поддерживается Chrome */ 
}
&:nth-of-type(1) {
  overflow-wrap: break-word;  /* не поддерживает IE, Firefox; является копией word-wrap */ 
  word-wrap: break-word;
  word-break: break-all;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */ 
  line-break: auto;  /* нет поддержки для русского языка */ 
  hyphens: manual;  /* значение auto не поддерживается Chrome */ 
}

&:nth-of-type(3) {
  overflow-wrap: normal;  /* не поддерживает IE, Firefox; является копией word-wrap */ 
  word-wrap: normal;
  word-break: normal;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */ 
  line-break: auto;  /* нет поддержки для русского языка */ 
 -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto;  /* значение auto не поддерживается Chrome */ 
}
}
.search__result:nth-of-type(5) {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	align-self: center;
}

.search__result:nth-of-type(4) {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	// height: 100%;
	// align-self: center;
	min-width: 130px;
	&>a {
		padding-top: 3px;
		// padding-bottom: 10px;
		&>img {
			margin-top: 10px;
		}
	}
	&>div {
		padding-top: 3px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		&>i {
			font-size: 48px;
		}
	}
}

.search_add {
	font-family: 'latobold';
	font-size: 14px;
	color: white;
	width: 128px;
	height: 44px;
	text-transform: uppercase;
	background-color: $darkblue;
	outline-color: $darkblue;
	cursor: pointer;
}


.search_add_icon {
	background-color: $red;
	height: 44px;
	width: 44px;
	background-color: $red;
	outline-color: $red;	
	color: white;
}

.search__result:nth-of-type(3) {
	min-width: 135px;
}