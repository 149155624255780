$blue: #1c4aa9;
$red: #f20732;
$black: #1c1e29;
$dark: #2b2d42;
$darkblue: #102858;
$graybg: #edeff3;
$graytext: #9091a2;
$bluebg: #144ec5;
$bluetext: #1b65ff;
$darkbg: #1c1d25;
