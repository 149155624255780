.menu-icon {
	width: 1180px;
	margin: 100px auto 0 auto;

	@include px1180 {
		width: 100%;
		padding: 10px;
	}
}

.menu-icon__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	
	@include px768 {

	}
}

.menu-icon__item {
	width: 33%;
	min-width: 280px;
	margin: 0;
	padding: 0 20px 0 0;
	margin-bottom: 60px;
	&:nth-of-type(1)>.menu-icon__url>.menu-icon__image:after {
		content: '1';
	}
	&:nth-of-type(2)>.menu-icon__url>.menu-icon__image:after {
		content: '2';
	}
	&:nth-of-type(3)>.menu-icon__url>.menu-icon__image:after {
		content: '3';
	}
	&:nth-of-type(4)>.menu-icon__url>.menu-icon__image:after {
		content: '4';
	}
	&:nth-of-type(5)>.menu-icon__url>.menu-icon__image:after {
		content: '5';
	}
	&:nth-of-type(6)>.menu-icon__url>.menu-icon__image:after {
		content: '6';
	}
	&:nth-of-type(7)>.menu-icon__url>.menu-icon__image:after {
		content: '7';
	}
	&:nth-of-type(8)>.menu-icon__url>.menu-icon__image:after {
		content: '8';
	}
	&:nth-of-type(9)>.menu-icon__url>.menu-icon__image:after {
		content: '9';
	}
	&:nth-of-type(10)>.menu-icon__url>.menu-icon__image:after {
		content: '10';
	}
	&:nth-of-type(11)>.menu-icon__url>.menu-icon__image:after {
		content: '11';
	}
	&:nth-of-type(12)>.menu-icon__url>.menu-icon__image:after {
		content: '12';
	}

}

.menu-icon__url {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	text-decoration: none;
}

.menu-icon__image {
	width: 80px;
	min-width: 80px;
	min-height: 80px;
	height: 80px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 30px;
	position: relative;
	&:after {
		position: absolute;
    left: 100%;
    top: 0;
    font-family: 'geometriaextrabold';
    font-size: 100px;
    margin: 0;
    padding: 0;
    color: white;
		text-shadow: 4px 0px 15px rgba(89, 89, 89, 0.05), -4px 0px 15px rgba(89, 89, 89, 0.05), 0px -4px 15px rgba(89, 89, 89, 0.05), 0px 4px 15px rgba(89, 89, 89, 0.05);
		z-index: -1;
	}
	
}

.menu-icon__title {
	font-family: 'russo_oneregular';
	color: #08234a;
	font-size: 18px;
	text-transform: uppercase;
	margin: 0;
}

.menu-icon__name {
	font-family: 'latoregular';
	font-size: 16px;
	color: #08234a;
}

.menu-red__title.h1 {
	color: #102858;
}

.menu-red__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 0 70px 0;
	padding: 0;
}

.menu-red__item {
	width: 25%;
	min-width: 140px;
	margin: 0;
	padding: 30px 10px;
	border: 1px solid #dddee3;
	transition: background-color .3s;
	&:hover {
		background-color: $red;
		transition: background-color .3s;
	}
	&:hover>.menu-red__url>.menu-red__name {
		color: white;
		transition: color .3s;
	} 	

	@include px420 {
		width: 100%;
	}
}

.menu-red__url {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	height: 100%;
}

.menu-red__image {
	width: 100%;
	height: 85px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.menu-red__name {
	font-family: 'russo_oneregular';
	font-size: 18px;
	color: $dark;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0;
	transition: color .3s;
}