.parts__search {
	background-color: $graybg;
	padding: 50px 105px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@include px768 {
		padding: 10px;
	}
}

.search__title.h1 {
	color: $dark;
	margin-bottom: 20px;
}

.search__block {
	width: calc(100%);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.search__block .search__input {
	padding: 3px 15px 5px 15px;
	transform: skewX(-20deg);
	width: 90%;

	@include px1240 {
		width: 80%;
	}
	@include px768 {
		width: 70%;
	}
}