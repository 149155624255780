@font-face {
    font-family: 'font';
    src: url('../fonts/9928.eot');
    src: url('../fonts/9928.eot?#iefix') format('embedded-opentype'),
         url('../fonts/9928.woff2') format('woff2'),
         url('../fonts/9928.woff') format('woff'),
         url('../fonts/9928.ttf') format('truetype'),
         url('../fonts/9928.svg#russo_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'material';
    src: url('../fonts/material-design-iconic-font.eot');
    src: url('../fonts/material-design-iconic-font.eot?#iefix') format('embedded-opentype'),
         url('../fonts/material-design-iconic-font.woff2') format('woff2'),
         url('../fonts/material-design-iconic-font.woff') format('woff'),
         url('../fonts/material-design-iconic-font.ttf') format('truetype'),
         url('../fonts/material-design-iconic-font.svg#material-design-iconic-font') format('svg');
    font-weight: normal;
    font-style: normal;
}





@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black.eot');
    src: url('../fonts/lato-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-black.woff2') format('woff2'),
         url('../fonts/lato-black.woff') format('woff'),
         url('../fonts/lato-black.ttf') format('truetype'),
         url('../fonts/lato-black.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoblack_italic';
    src: url('../fonts/lato-blackitalic.eot');
    src: url('../fonts/lato-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-blackitalic.woff2') format('woff2'),
         url('../fonts/lato-blackitalic.woff') format('woff'),
         url('../fonts/lato-blackitalic.ttf') format('truetype'),
         url('../fonts/lato-blackitalic.svg#latoblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latobold';
    src: url('../fonts/latobold.eot');
    src: url('../fonts/latobold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/latobold.woff2') format('woff2'),
         url('../fonts/latobold.woff') format('woff'),
         url('../fonts/latobold.ttf') format('truetype'),
         url('../fonts/latobold.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latobold_italic';
    src: url('../fonts/latobolditalic.eot');
    src: url('../fonts/latobolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/latobolditalic.woff2') format('woff2'),
         url('../fonts/latobolditalic.woff') format('woff'),
         url('../fonts/latobolditalic.ttf') format('truetype'),
         url('../fonts/latobolditalic.svg#latobold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latohairline';
    src: url('../fonts/lato-hairline.eot');
    src: url('../fonts/lato-hairline.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-hairline.woff2') format('woff2'),
         url('../fonts/lato-hairline.woff') format('woff'),
         url('../fonts/lato-hairline.ttf') format('truetype'),
         url('../fonts/lato-hairline.svg#latohairline') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoitalic';
    src: url('../fonts/latoitalic.eot');
    src: url('../fonts/latoitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/latoitalic.woff2') format('woff2'),
         url('../fonts/latoitalic.woff') format('woff'),
         url('../fonts/latoitalic.ttf') format('truetype'),
         url('../fonts/latoitalic.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoregular';
    src: url('../fonts/latoregular.eot');
    src: url('../fonts/latoregular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/latoregular.woff2') format('woff2'),
         url('../fonts/latoregular.woff') format('woff'),
         url('../fonts/latoregular.ttf') format('truetype'),
         url('../fonts/latoregular.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'material-desidesigniconicfont';
    src: url('../fonts/material-design-iconic-font.eot');
    src: url('../fonts/material-design-iconic-font.eot?#iefix') format('embedded-opentype'),
         url('../fonts/material-design-iconic-font.woff2') format('woff2'),
         url('../fonts/material-design-iconic-font.woff') format('woff'),
         url('../fonts/material-design-iconic-font.ttf') format('truetype'),
         url('../fonts/material-design-iconic-font.svg#material-desidesigniconicfont') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'russo_oneregular';
    src: url('../fonts/russoone-regular.eot');
    src: url('../fonts/russoone-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/russoone-regular.woff2') format('woff2'),
         url('../fonts/russoone-regular.woff') format('woff'),
         url('../fonts/russoone-regular.ttf') format('truetype'),
         url('../fonts/russoone-regular.svg#russo_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;

}