.footer-wrapper {
	width: 100%;
	background-color: $dark;
	border-top: 2px solid $blue;
	margin-top: 60px;
}

.footer {
	width: 1180px;
	margin: 0 auto;
	padding-top: 25px;

	@include px1180 {
		width: 100%;
		padding: 25px 10px;
	}
}

.footer__top {
	background-color: $dark;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.logo__tittle_footer {
color: $bluetext;
margin-bottom: 0;
margin-top: 0;
}

.logo__subtittle_footer {
	color: white;
	margin-top: 0;
}

.footer__menu-items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;

	@include px1024 {
		display: none;
	}
}

.footer__menu-item {
	margin: 0;
	padding:	0;
	margin-right:	42px;
	&:nth-last-of-type(1) {
		margin-right: 0;
	}
}

.footer__menu-url {
	font-family: 'latoregular';
	font-size: 12px;
	text-decoration: none;
	text-shadow: none;
	transition: text-shadow .2s;
	color: white;
	text-transform: uppercase;
	&:hover {
		// text-decoration: underline;
		transition: text-shadow .2s;
		text-shadow: 0px 1px 13px #CECECE;
	}

}

.footer__middle {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 20px;

	@include px768 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}

.footer_oferta {
	font-family: 'latohairline';
	font-size: 11px;
	color: white;
	line-height: 1.273;
	width: 70%;
	margin: 0;

	@include px768 {
		font-size: 13px;
		width: 100%;
	}
}

.socials {
	margin-left: 40px;

	@include px767 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-left: 0;
	}
}

.social__title {
	font-family: 'russo_oneregular';
	font-size: 16px;
	color: white;
	text-transform: uppercase;

	@include px767 {
		margin-right: 20px;
	}

}

.social__icons {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;	
}

.social__icon {
	margin: 0 25px 0 0;
	padding: 0;
	@include px767 {
		margin-right: 10px;
	}
}

.social__url {
	display: block;
	// width: 35px;
	// height: 35px;
	padding: 8px;
	border: 2px solid $blue;
	border-radius: 50%;
	background-color: $dark;
	transition: background-color .2s;
	&:hover {
		background-color: $blue;
		transition: background-color .2s;
	}
}

.social__img {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.footer__bottom-wrapper {
	width: 100%;
	background-color: $darkbg;
}

.footer__bottom {
	width: 1240px;
	margin: 0 auto;
	padding: 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@include px767 {
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	@include px1280 {
		width: 100%;
		padding: 20px 10px;
	}
}

.copyright {
	font-family: 'latoregular';
	font-size: 12px;
	text-transform: uppercase;
	color: white;

	@include px767 {
		text-align: center;
	}
}

.politic {
	font-family: 'latoregular';
	font-size: 12px;
	text-transform: uppercase;
	color: white;	
	&:hover {
		text-decoration: none;
	}

	@include px767 {
		text-align: center;
	}
}