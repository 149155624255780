.model-list {
	width: 1180px;
	margin: 0 auto;

	@include px1180 {
		width: 100%;
		padding: 10px;
	}
}

.model-list__items {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	@include px1024 {
		justify-content: center;
	}

}

.model__item {
	width: 350px;
	max-width: 350px;	
	border: 1px solid #dddee3;
	margin: 10px;
	transition: .3s;
	min-width: 350px;
	&:nth-of-type(1) {
		margin-left: 0;
	}

	&:nth-last-of-type(1) {
		margin-right: 0;
	}	

	@include px600 {
		width: 100%;
		min-width: 320px;
	}
}

.models__title {
	font-family: 'russo_oneregular';
	font-size: 26px;
	color: $darkblue;
	text-transform: uppercase;
	letter-spacing: 1.3px !important;
	margin: 25px 0 5px 0;

}

.model__img {
	width: 100%;
	height: 244px;
	background-size: cover;
	background-repeat: no-repeat;
}

.model__item>.model__img {
	height: auto !important;
	min-height: 250px;
	margin-bottom: 0;
}

.model__description {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px 20px 10px 20px;

	&>.model__title {
		font-size: 24px;
	}
}

.model__title {
	font-family: 'latobold';
	font-size: 18px;
	color: $darkblue;
	text-transform: uppercase;
	margin-bottom: 10px;
	transition: .3s;
	
}

.model__info {
	font-family: 'latoregular';
	font-size: 14px;
	font-weight: bold;
	color: $dark;
	line-height: 20px;
	letter-spacing: 1.2px;
	text-decoration: none;
}

.model__online {
	margin: 10px 0;
	font-family: 'latoregular';
	font-size: 14px;
	font-weight: bold;
	color: $red;	
	letter-spacing: 1.2px;
}

.model__details {
	font-family: 'latobold';
	font-size: 18px;
	color: white;	
	width: 100%;
	padding: 10px 20px;
	text-transform: uppercase;
	background-color: #08234a;
	display: block;
	position: relative;
	text-decoration: none;
	transition: .3s;
	&:after {
		content: '\f301';
		font-family: 'Material-Design-Iconic-Font';
		font-size: 18px;
		padding: 10px 15px;
		color: white;
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-100%,-50%);
		// width: 50px;
		// height: 50px;
		background-color: $red;
		border-left: 1px solid white;
	}
}

.model__item:hover {
	cursor: pointer;
	transition: .3s;
	box-shadow: 0px 9px 22px 0px rgba(167, 172, 182, 0.4);
	&>.model__description>.model__title {
		color: $red;
		transition: .3s;
	}
	&>.model__details {
		background-color: $red;
		transition: .3s;
	}	
}