.devices__items {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;
}

.devices__item {
	margin-bottom: 32px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	transition: .2s;
	&:hover>.devices__url>.devices__info>.device__title {
		color: red;
		transition: .3s;
	}	

}

.devices__url {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	text-decoration: none;
	&:hover>.devices__info>.device__title {
		color: $red;
		text-decoration: underline;
	}	

	@include px767 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	
}

.devices__image {
	width: 325px;
	min-width: 325px;
	height: 240px;
	border: 1px solid #dddee3;
	margin-right: 47px;
	background-size: cover;
	background-repeat: no-repeat;

	@include px767 {
		width: 100%;
		padding: 10px;
		height: 300px;
		margin-right: 0;
	}
}

.devices__info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% - 372px);
	margin-right: 0;
	position: relative;
	&>a {
		align-self: flex-end;
	}

	@include px767 {
		align-items: center;
		width: 100%;
	}
}

.device__title {
	font-family: 'latobold';
	font-size: 24px;
	color: #2b2d42;
	text-transform: uppercase;
	margin-bottom: 0;

	@include px768 {
		font-size: 18px;
	}
}

.device__info-text {
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;

	@include px768 {
		font-size: 14px;
	}
	
}

.devices__url {
	text-decoration: none;
	width: 100%;
}



.button__news_more {
	margin-right: 45px;
	background-color: #08234a;
	align-self: flex-end;
	position: relative;
	transition: .2s;
	
	&:after {
		content:'\f2ee';
		font-family: 'Material-Design-Iconic-Font';
		position: absolute;
		left: 100%;
		top: 0;
		background-color: #144ec5;
		color: white;
		padding: 16px;
		margin-left: 3px;
		transition: .2s;

	}
	&:hover {
		background-color: #08234a;
	}

}

.devices__item:hover>.devices__url>.devices__info>.button__news_more:after {
		background-color: #f20732 ;
		transition: background-color .2s;
}

.devices__item:hover {
	box-shadow: 0px 15px 25px -8px rgba(0,0,0,0.46);
	transition: .2s;
}

.device__info {
	font-family: 'latoregular';
	font-size: 18px;
	color: #2b2d42;
}

button.button.button_list {
	position: absolute;
	right: 0;
	bottom: 0;
	padding-right: 70px;
	&:after {
		content: '\f301';
		font-family: 'Material-Design-Iconic-Font';
		position: absolute;
		right: 0;
		top: 50%;
		color: white;
		margin-left: 50px;
		transform: translateY(-50%);
		background-color: $red;
		padding: 16px;
		border-left: 1px solid white;
	}

	@include px767 {
		position: relative;
	}
}

span.button {
	position: absolute;
	right: 0;
	bottom: 0;
	padding-right: 70px;
	
	&:after {
		content: '\f301';
		font-family: 'Material-Design-Iconic-Font';
		position: absolute;
		right: 0;
		top: 50%;
		color: white;
		//margin-left: 50px;
		transform: translateY(-50%);
		background-color: $red;
		padding: 16px;
		padding-right: 30px;
		border-left: 1px solid white;
		@include px767 {
			margin-left: 0px;
			padding-right: 30px;
			
		}
	}

	@include px767 {
		position: relative;
		display: block;
		padding-right: 40px;
		margin-left: 0;
		font-size: 12px;

		
	}
}